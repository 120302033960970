import React from 'react'
import Layout from '../../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import episodeArt from '../../images/song_art/ep7_art.png'

const Episode5 = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">afraid debbie downer may be right this time</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container podcast is-fluid">
        <div className="columns name">
          <div className="column">
            <h1 className="is-size-4">Episode 7: Planet My Love</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <img src={episodeArt} className="episode-art" alt="planet with atmosphere escaping into space (with overlay: 'your rivers run empty, my air fades away'" />
             <a className="img-cite" href="https://pngtree.com/freebackground/planet-moon-space-star-background_185272.html">pngtree.com</a>
             <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8188427/height/50/theme/legacy/thumbnail/no/preload/no/direction/backward/" height="50" width="100%" scrolling="no"></iframe>
          </div>
          <div className="column is-two-thirds transcript">
            <p>
              Welcome back to Planet and Sky, a cosmic love story. As usual, I urge the listener to begin with the beginning with episode 1, Something is Dreaming, in order to make sense of this strange tale of scientists and microbes, raging planets, terrifying storms, and great love and, as we are about to discover, heartbreaking loss.
            </p>

            <p>
              Sky was not well. She knew why the seasons were changing and why their children were in such terrible distress. She also knew that she couldn’t shield Planet from the truth any longer. She had been his protection from the harsh realities of deep space for as long as she could remember. Though she couldn’t stop every meteorite and comet from the furthest reaches of their system from occasionally crashing through and impacting Planet, she had now found a nemesis that she had no power whatsoever to defeat.
            </p>

            <p>
              She could have blamed Planet if she’d wanted to lash out in her despair, but there was nothing he could do about it. He had been formed from the leftover remnants of their star’s formation long before she came to be, and his distance from that star was just right to stabilize an ideal  temperature for the development of Sky and eventually for their children to grow and thrive. The problem was that he was just not massive enough to hold onto her any longer. She’d always been losing some of her material into space, but Planet had been replacing what was lost through his relentless eruptions. He’d now been gradually cooling internally and the joyful volcanoes weren’t happening so often any more. Also, his internal dynamics had once created a powerful magnetic shield around her and this was now failing to protect her. She was gradually drifting off into space and there was no way either of them could halt the process.
            </p>

            <p>
              She painfully began her mournful song, “Planet my love, I know that we’re dying. Your rivers run empty… my air fades away. Our children retreat into your deepest havens. Your surface lies barren… ‘neath the sun’s deadly rays.”
            </p>

            <p>
              She felt no anger toward him, only the impending bitter loss of what they had built between them. She felt they had failed the helpless children who desperately needed her warmth and nurturing rains. They would helplessly watch their lush forests burn off and turn to deserts. The deep basins in which so many amazing and varied creatures flourished would become smaller and shallower until there was nothing left at all. It tore at her heart to imagine it.
            </p>

            <p>
              An even greater torment, the greatest of all in fact, was for the loss of her connection to her dear companion, the only love of her long life. There was really no Planet and Sky. They had become one being.
            </p>

            <p>
              Planet and Sky had merged completely and were now truly a single world. It was inconceivable that they could be separated. Sky felt no sorrow for the termination of her own separate existence, but she couldn’t bear to think of Planet without her nourishing protection from the bitter cold and terrifying darkness of deep space. He would be raked by the powerful blasts of radiation from their star which cared nothing for them. The end was upon them, and she knew it.
            </p>

            <p>
              She sang, “the pain in my heart, the sorrow I feel, the dread separation, this grief is too real!”
            </p>

            <p>
              Was there ultimately no reason for them to have found each other? Was their love and happiness just a cruel joke? Perhaps she should never have spoken to him so long ago. Maybe it would have been better to stay an impersonal mass of meaningless particles and let this nonsensical reality have its incomprehensible way and end up however it chose to.
            </p>

            <p>
              No. She couldn’t believe that. What they had built together was more than just physical processes and interactions. They had gone through so much together, building the amazing topography of their world and populating it with complex life forms that harbored fears and desires, love for each other and their children, and even some with grand ambitions that could have evolved to reach astonishing capabilities in time. Perhaps some of those most complex beings - maybe those apes for instance - might have someday developed skills to escape the gravity well of this world and gone off on adventures to other worlds entirely!
            </p>

            <p>
              Most of all, they had fallen deeply in love. The union they had formed, the joyous bond they had was too precious to vanish entirely. It was something that felt far larger than their individual selves. They had created meaning from nothing and brought it to an entire world of beings. She refused to hold any wish for any alternative to what had happened.
            </p>

            <p>
              Again she sang, “…but I can’t regret… the moment I found you. The joy of our union - in eternity bound.”
            </p>

            <p>
              Sky thought about those creatures below. They were born, lived and died in mere moments compared to her journey with Planet. They cared for one another for their brief instant, explored their tiny realms and found joy in their discoveries, and held dreams for their descendants after they would inevitably fail to keep the flame of their individual lives alight. They believed that somehow the rules of life made sense.
            </p>

            <p>
              But they had no idea of the cruelty and complete lack of pity and compassion this universe held for them. They were mere algorithms. Mathematical patterns, formulae and equations, toys for absent gods to play with. There was no grand scheme of things. Only a pointless confusion of empty processes. Eventually it would all vanish back into the oblivion from wherever it had come. Why did anything even bother to be?
            </p>

            <p>
              “Fairness, beauty, compassion,” she sang, “the province of good mortal beings. The universe cares not for its children - who wait for its pitiless sting.”
            </p>

            <p>
              Her anger subsided. There was only a sense of resignation now. Planet seemed to understand and accept their fate. He was stoic as always - or maybe just stunned? She would be have to be content with the knowledge that they had seized the opportunity they had been presented and had made the most of it.
            </p>

            <p>
              Boldly she sang, “but we made a gift unto heaven, our love - precious and free. As we fade to oblivion… love is our victory!”
            </p>

            <p>
              The reality was that their particles would dissolve and go off into nothingness. The end of their mutual being had to be faced with courage and fortitude. The time had come to say goodbye to this life as partners in a grand adventure. They had created love and it was a glorious gift in an empty void that for a not-so-brief moment was powerful and meaningful.
            </p>

            <p>
              “Planet my love,” she cried again, “I’ll join you in dying. There’s no greater glory - than to share our last day…”
            </p>

            <p>
              As they had created this wondrous love and the beauty it contained, they could also declare victory over the pointlessness and lovelessness of a cruel cosmos. Their love was real and true and eternal… with or without their physical beings. They dared the universe - just try to kill it.
            </p>

            <p>
              She sang, “…so let us embrace - as we walk through this door. Though our life here is over - our love will yet live…. forevermore.”
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default Episode5
